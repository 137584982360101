import Editor from "../components/Editor.vue";
import modalBus from "../bus/modal-bus";
import notifBus from "../bus/notification-bus";

export default {
  data() {
    return {
      api: "api/article", // replace this in component
      routeList: "article", // replace this in component
      loading: null,
      currentLanguage: this.$cookies.get("usr_lang"),
      translations: [],
      datesAndTimes: [],
      tempImage: null,
      address: null,
      article: {
        token: null,
        publishing_date: null,
        address_name: null,
        image: null,
        is_published: true,
      },
    };
  },
  components: {
    Editor,
  },
  methods: {
    toggleConfirm(which) {
      let functionToCall = which === "delete" ? this.deleteMethod : this.duplicate;
      let message = which === "delete" ? "Want to DELETE this news ?" : "Want to DUPLICATE this news ?";
      modalBus.$emit("set-size", {
        width: "400px",
        "margin-top": "50px",
      });
      modalBus.$emit("change", {
        component: "Prompt",
        data: {
          methodToCall: functionToCall,
          message,
        },
      });
      modalBus.$emit("toggle");
    },

    deleteMethod() {
      this.$axios.delete(this.api + "/" + this.article.token).then((res) => {
        notifBus.$emit("show", {
          status: "success",
          message: this.$t("projects.notifications.deleted"),
        });
        this.$router.push({ name: this.routeList });
      });
    },
    save() {
      this.loading = true;
      const formData = new FormData();
      if (this.article.image && this.article.image.length > 400) {
        this.article.image = null;
      }
      if (this.$refs.image.files[0]) formData.append("image", this.$refs.image.files[0]);
      formData.set("article", JSON.stringify(this.article));
      formData.set("translations", JSON.stringify(this.translations));
      formData.set("address", JSON.stringify(this.address));
      formData.set("dates_and_times", JSON.stringify(this.datesAndTimes));
      this.$axios.post(this.api, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => {
        this.loading = false;
        const message = this.article.id ? "News updated" : "News created";
        notifBus.$emit("show", {
          status: "success",
          message,
        });
        this.$router.push({ name: this.routeList });
      });
    },
    addEmail() {
      this.emails.push({ value: "" });
    },
    removeEmail(toDelete) {
      this.emails = this.emails.filter((el) => el.value !== toDelete);
    },

    addPhone() {
      this.phones.push({ value: "" });
    },
    removePhone(toDelete) {
      this.phones = this.phones.filter((el) => el.value !== toDelete);
    },
    previewFiles(event) {
      let reader = new FileReader();
      let _this = this;
      reader.onload = function() {
        _this.tempImage = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    fetch() {
      if (this.$route.params.token !== undefined) {
        this.$axios.get(this.api + "/" + this.$route.params.token).then((res) => {
          let article = res.data;
          this.datesAndTimes = res.data.dates_and_times;
          this.address = res.data.address;
          this.translations = this._global.availableLanguages.map((l) => {
            const index = article.translations.findIndex((t) => t.lang === l.value);
            if (index !== -1) {
              const temp = { ...article.translations[index] };
              article.translations.splice(index, 1);
              return temp;
            }

            return {
              lang: l.value,
              title: "",
              description: "",
              slug: "",
            };
          });

          this.article = res.data.article;
        });
      }
    },
  },
  watch: {
    translations: {
      deep: true,
      immediate: true,
      handler(el) {},
    },
    "$store.state.global.availableLanguages": {
      immediate: true,
      handler(el) {
        this.translations = this._global.availableLanguages.map((el) => {
          return {
            lang: el.value,
            title: "",
            description: "",
            slug: "",
          };
        });
      },
    },
  },
  created() {},
};
